.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100vw;
    background-color: #EEEEEE;
    position: absolute;
    bottom: 0;
    z-index: 10;
    flex-direction: column;
    gap: 20px
}
.loadingIcon{
    animation: spin 2s linear infinite;
}
.txt{
    font-size: 22.87px;
    font-weight: 600;
    line-height: 27.29px;
    text-align: center;
    color: #006E54;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

