.container {
    display: flex !important;
    flex-direction: row-reverse !important;
    gap: 2em;
    justify-content: center !important;
    margin: 1.5em 0 1.5em 0;
    width: 100% !important;
}

.defaultBtn {
    background: #006E54 !important;
    border-radius: 50em !important;
    color: #ffffff !important;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    margin-left: 1rem;
    opacity: 1;
    padding: 1em 0 !important;
    text-transform: capitalize !important;
    width: 12rem;

    &:hover {
        background: #009773 !important;
    }

    &:disabled {
        background: #63D1A2 !important;
    }
}

.redBtn {
    color: #ffffff !important;
    background: #D44141 !important;
    border-radius: 50em !important;
    font-weight: 300;
    font-family: "Roboto", sans-serif !important;
    margin-left: 1rem;
    opacity: 1;
    padding: 1em 0 !important;
    text-transform: capitalize !important;
    width: 12rem;

    &:hover {
        background: #D44141 !important;
    }

    &:disabled {
        background: #A7A7A7 !important;
    }
}

.whiteBtn {
    background: #ddd !important;
    border-radius: 50em !important;
    color: inherit !important;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    opacity: 1;
    padding: 1em 0 !important;
    text-transform: capitalize !important;
    width: 12rem;
}