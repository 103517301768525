.button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 14px 6px 8px;
    border-radius: 20px;
    gap: 4px;
    color: #fff;
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    background-color: #006E54;
}
.modalContainer{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000050;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal{
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 16px;
}
.header{
    border-bottom: 1px solid #D5DDED;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    padding-bottom: 16px;
}
.txt{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #06132F;
    width: max-content;
    span{
        font-weight: 700;
    }
}
.input{
    width: 100%;
}
.disabled{
    background-color: #48655f80;
}
.containerTable{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #E5E9F0;
    .headerTable{
        background-color: #eee;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        color: #595959;
        div{
            display: flex;
            flex: 1;
            justify-content: center;
            width: 100%;
        }
        .deleteBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            border-radius: 32px;
            color: #fff;
            flex: auto;
            cursor: pointer;
            width: 32px !important;
            flex: none;
        }
    }
    .bodyTable{
        font-size: 16px;
        font-weight: 400;
        min-height: 20px;
        text-align: center; 
        color: #5F6689;
        .rowTable{
            border-top: 1px solid #E5E9F0;
            display: flex;
            justify-content: space-between;
            padding: 4px 12px;
            align-items: center;
            div{
                display: flex;
                flex: 1;
                justify-content: center;
                width: 100%;
                height: fit-content;
            }
            .deleteBtn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;
                border-radius: 32px;
                color: #fff;
                flex: auto;
                cursor: pointer;
                width: 32px !important;
                height: 32px;
                flex: none;
                background-color: #EEEEEE;
                svg{
                    filter: brightness(0) saturate(100%) invert(33%) sepia(17%) saturate(2385%) hue-rotate(119deg) brightness(95%) contrast(101%);
                }
            }
        }
    }
}
.containerInput{
    label{
        color: #006E54;
        font-size: 18px;
    }
    p{
        color: #006E54 !important;
        font-size: 14px !important;
    }
}