.container{
    height: 70vh;
    padding: 16px;
    .containerButtons{
        display: flex;
        gap: 8px;
        padding-bottom: 16px;
        justify-content: flex-end;
    }
}
