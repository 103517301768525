.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.img{
    width: 200px;
}
.title{
    font-size: 32px;
    font-weight: 600;
    line-height: 41.77px;
    text-align: center;
    color: #006E54;
    max-width: 585px;
}
.txt{
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    color: #2b2b2a;
    text-align: center;
    max-width: 434px;
    margin-bottom: 54px;
}