div.solicitudes {
    background-color: #f2f2f2;
    border-radius: 12px;
    padding: 20px;
    width: auto;
    // grid-row: 2/2;
    // grid-column: 2/2;

    div.titleContainer {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 24px;
        padding-left: 28px;

        img {
            width: 56px;
            margin-right: 20px;
        }

        h4 {
            font-size: 35px;
            line-height: 46px;
            font-weight: 300;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            color: #a7a7a7;
        }
    }

    div.row {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 36px;
        margin-bottom: 36px;
        gap: 24px;
        div{
            display: flex;
            align-items: center;
            gap: 16px;
            h4, p{
                font-size: 24px;
                color: #a7a7a7;
            }
            svg{
                color: #a7a7a7;
                cursor: pointer;
                height: 30px;
                width:auto
            }
        }
    }

    button {
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        background-color: transparent;
        border: 1px solid #e56da1;
        text-align: center;
        border-radius: 10px;
        margin-right: auto;
        padding: 8px 12px;
        width: 200px;

        a {
            text-decoration: none;
            color: #e56da1;
        }
    }

    @media all and (max-width: 1240px) {
        grid-column: 1/2;
        grid-row: 4/5;
    }
}