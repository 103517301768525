.containerTitle{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    padding: 35px;    
    
   
    h1{ 
        max-width: 485px;
        text-align: center;
        color:#006E54;
        font-size: 25px;
        font-weight: 600;
    
    }
    p{
        max-width: 52ch;
        margin-top: 20px;
        text-align: center;
        font-size: 15px;
        color: #999999;
        font-weight: 400;
        width: 150%;
    }
}