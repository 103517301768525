main.main {
    // min-height: calc(100vh - 150px);
    // padding: 0 0.5em;
    // overflow-y: scroll;
    overflow-y: auto;

    div.container {
        padding: 44px 18px;

        div.cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 16px;

            @media all and (max-width: 1383px) {
                display: flex;
                flex-direction: column;
            }
        }
    }
}