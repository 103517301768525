.page{
    padding-top: 8em;
}
.containerColumn{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.containerText{
    max-width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding-left: 4em;
}
.title{
    color: #006E54;
    font-size: 66px;
    font-size: clamp(44px, 4vw, 66px); 
    font-weight: 700;
    line-height: clamp(60px, 4vw, 78px);
}
.text{
    color: #707070;
    font-size: clamp(14px, 4vw, 28px);
    font-weight: 400;
    line-height: clamp(24px, 4vw, 38px);
}
.img{
    width: 55%;
}
.wave{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.version{
    position: absolute;
    bottom: 0;
    padding: 8px;
    font-size: 14px;
}