.containerCharts{
    width: 98vw;
    // height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    // align-items: center;
    .charts {
        background-color: white;
        position: absolute;
        margin-top: 30px;
        grid-row: 4/5;
        grid-column: 1/4;
        min-width: 600px;
        // height: 280px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px;
        border-radius: 12px;
        box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
        .containerTop {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            h3{
                font-size: 32px;
                font-weight: 400;
                color: #a7a7a7;
            }
            svg{
                cursor: pointer;
            }
        }
    }
}
div.card {
    background-color: #f2f2f2;
    border-radius: 12px;
    padding: 20px;
    width: auto;
    // grid-row: 1/2;

    div.titleContainer {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 24px;
        padding-left: 28px;

        img {
            width: 46px;
            margin-right: 20px;
        }

        h4 {
            font-size: 35px;
            line-height: 46px;
            font-weight: 300;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            color: #a7a7a7;
        }
    }
    div.bodyContainer {
        padding: 0 8px;
        // display: grid;
        // grid-template-columns: 280px auto auto;
        // grid-template-rows: auto auto auto auto;
        grid-row-gap: 42px;
        row-gap: 42px;
        .row{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 24px;
            div.total {
                color: #e56da1;
                background-color: #fff;
                border: 1px solid #e56da1;
                padding: 16px 8px;
                border-radius: 18px;
                box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
                text-align: center;
                margin-right: 20px;
                min-width: 242px;
                width: 45%;
                p {
                    font-size: 16px;
                    line-height: 20px;
                }
    
                h4 {
                    font-size: 32px;
                    line-height: 48px;
                    font-weight: 500;
                }
    
                .divider {
                    padding: 1px 0;
                    background-color: #e56da1;
                    margin-top: 16px;
                    margin-bottom: 12px;
                }
    
                .horizontalDivider {
                    font-size: 24px;
                    font-weight: 700;
                    margin-right: 16px;
                    display: none;
                }
            }
            .right{
                width: 48%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 12px;
                div.parcial {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
        
                    img {
                        max-width: 30px;
                        margin-right: 8px;
                        margin-left: 12px;
                    }
        
                    div.ventas {
                        border-radius: 26px;
                        padding: 8px 4px 14px;
                        text-align: center;
                        background-color: transparent;
                        position: relative;
                        width: 121px;
                        margin-right: 92px;
        
                        p {
                            font-size: 10px;
                            line-height: 15px;
                        }
        
                        h4 {
                            font-size: 20px;
                            line-height: 25px;
                            font-weight: 500;
                        }
        
                        div.operaciones {
                            border-radius: 26px;
                            text-align: center;
                            padding: 8px 14px;
                            position: absolute;
                            right: -82px;
                            top: 4px;
                        }
                    }
        
                    div.operaciones {
                        color: #fff;
                        border-left: none;
                    }
        
                    &.debito {
                        grid-column: 3/4;
                        grid-row: 1/2;
        
                        div.ventas {
                            color: #63d1a2;
                            border: 1px solid #63d1a2;
                        }
        
                        div.operaciones {
                            background-color: #63d1a2;
                            border: 1px solid #63d1a2;
                        }
                    }
        
                    &.hooli {
                        div.ventas {
                            color: #5567ae;
                            border: 1px solid #5567ae;
                        }
        
                        div.operaciones {
                            background-color: #5567ae;
                            border: 1px solid #5567ae;
                        }
                    }
        
                    &.credito {
                        grid-column: 2/3;
                        grid-row: 2/3;
        
                        div.ventas {
                            color: #006E54;
                            border: 1px solid #006E54;
                        }
        
                        div.operaciones {
                            background-color: #006E54;
                            border: 1px solid #006E54;
                        }
                    }
        
                    &.transfer {
                        div.ventas {
                            color: #63c3d1;
                            border: 1px solid #63c3d1;
                        }
        
                        div.operaciones {
                            background-color: #63c3d1;
                            border: 1px solid #63c3d1;
                        }
                    }
            }
            }
        }


        div.buttonsContainer {
            display: flex;
            grid-column: 1/4;
            grid-row: 3/4;

            button {
                margin: 0 8px;
                -webkit-flex-grow: 1;
                flex-grow: 1;
                padding: 8px 16px;
                border: 1px solid #e56da1;
                border-radius: 10px;
                font-size: 14px;
                line-height: 19px;
                cursor: pointer;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                -webkit-align-items: center;
                align-items: center;
                transition: all .2s ease-in-out;
                text-align: center;

                &.selected {
                    background-color: #e56da1;

                    span,
                    a {
                        color: #FFF;
                    }
                }

                span,
                a {
                    text-decoration: none;
                    -webkit-flex-grow: 1;
                    flex-grow: 1;
                    color: #e56da1;
                    margin-right: 4px;
                }
            }
        }

//         @media all and (max-width: 1760px) {
//             grid-template-columns: 25% 25% 25% 25%;
//             grid-template-rows: auto auto auto auto auto;
//             max-width: 100%;
//             justify-items: flex-start;
//             grid-row-gap: 24px;
//             row-gap: 24px;

//             div.total {
//                 grid-column: 1/4;
//                 grid-row: 1/2;
//                 margin: 0;
//                 padding: 16px 32px;
//                 display: -webkit-flex;
//                 display: flex;
//                 -webkit-align-items: center;
//                 align-items: center;
//                 -webkit-justify-content: space-between;
//                 justify-content: space-between;

//                 h4 {
//                     margin: 0 16px;
//                 }

//                 div.horizontalDivider {
//                     display: block;
//                 }
//             }

//             div.parcial {
//                 &.debito {
//                     grid-column: 3/5;
//                     grid-row: 2/3;
//                 }

//                 &.credito {
//                     grid-column: 1/3;
//                     grid-row: 3/4;
//                 }

//                 &.hooli {
//                     grid-column: 1/3;
//                     grid-row: 2/3;
//                 }

//                 &.transfer {
//                     grid-column: 3/5;
//                     grid-row: 3/4;
//                 }
//             }

//             div.buttonsContainer {
//                 grid-column: 1/5;
//                 grid-row: 4/5;
//             }

//             div.charts {
//                 grid-row: 5/6;
//                 grid-column: 1/5;
//             }
//         }
//     }

//     @media all and (max-width: 1240px) {
//         &:first-of-type {
//             grid-column: 1/2;
//             grid-row: 1/2;
//         }

//         &:nth-of-type(2) {
//             grid-column: 1/2;
//             grid-row: 2/3;
//         }

//         div.bodyContainer {
//             grid-template-columns: 280px auto auto;
//             grid-template-rows: auto auto auto auto;
//             justify-items: normal;
//             grid-row-gap: 42px;
//             row-gap: 42px;

//             div.total {
//                 grid-column: 1/2;
//                 grid-row: 1/3;
//                 margin-right: 20px;
//                 display: block;
//             }

//             div.parcial {
//                 &.debito {
//                     grid-column: 3/4;
//                     grid-row: 1/2;
//                 }

//                 &.credito {
//                     grid-column: 2/3;
//                     grid-row: 2/3;
//                 }

//                 &.hooli {
//                     grid-column: 2/3;
//                     grid-row: 1/2;
//                 }

//                 &.transfer {
//                     grid-column: 3/4;
//                     grid-row: 2/3;
//                 }
//             }

//             div.buttonsContainer {
//                 grid-column: 1/4;
//                 grid-row: 3/4;
//             }

//             div.charts {
//                 grid-row: 4/5;
//                 grid-column: 1/4;
//             }
//         }
//     }

//     @media all and (max-width: 900px) {
//         div.bodyContainer {
//             grid-template-columns: 1fr 1fr;
//             grid-template-rows: auto auto auto auto auto;
//             max-width: 100%;
//             justify-items: flex-start;
//             grid-row-gap: 24px;
//             row-gap: 24px;
//             column-gap: 24px;

//             div.total {
//                 grid-column: 1/4;
//                 grid-row: 1/2;
//                 margin: 0;
//                 padding: 16px 32px;
//                 display: -webkit-flex;
//                 display: flex;
//                 -webkit-align-items: center;
//                 align-items: center;
//                 -webkit-justify-content: space-between;
//                 justify-content: space-between;
//             }

//             div.parcial {
//                 &.debito {
//                     grid-column: 2 / 3;
//                     grid-row: 2/3;
//                 }

//                 &.credito {
//                     grid-column: 1/2;
//                     grid-row: 3/4;
//                 }

//                 &.hooli {
//                     grid-column: 1/2;
//                     grid-row: 2/3;
//                 }

//                 &.transfer {
//                     grid-column: 2 / 3;
//                     grid-row: 3/4;
//                 }
//             }

//             div.buttonsContainer {
//                 grid-column: 1/5;
//                 grid-row: 4/5;
//             }

//             div.charts {
//                 grid-row: 5/6;
//                 grid-column: 1/5;
//             }
//         }
    }
}