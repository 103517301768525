.page{
    display: flex;
    background-color: #f0f0f0;
    height: 90vh;
    justify-content: center;
    padding-block: 2em;
    align-items: center;
    box-sizing: border-box;
}
.container{
    background-color: #fff;
    min-width: 800px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;
    min-height: 500px;
    padding: 3em 6em 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.noPadding{
    padding-inline: 0;
}
.container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.container::-webkit-scrollbar:vertical {
    width:8px;
    height: 20px;
    margin-top: 10px;
}

.container::-webkit-scrollbar-button:increment,.container::-webkit-scrollbar-button {
    display: none;
} 

.container::-webkit-scrollbar:start{
    margin-top: 15px;
}

.container::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.container::-webkit-scrollbar-track {
    border-radius: 50px;  
}
.containerTitle{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100px;   
    max-width: 550px;
   
    h1{ 
        text-align: center;
        color:#006E54;
        font-size: 30px;
        font-weight: 600;
    }
    p{
        margin-top: 12px;
        text-align: center;
        font-size: 18px;
        color: #2b2b2a;
        font-weight: 400;
        width: 150%;
        text-wrap: balance;
    }
}
// .linear{ 
//     border-bottom: 1px solid #EEEEEE;
//     width: 150%;
//     margin-top: 12px;
// }

.close{ 
    width: 100%;
    display: flex;
    justify-content: end;
    position: relative;
    img{ 
        cursor: pointer;
        z-index: 2;
        right: -30px;
        top: -15px;
        position: absolute;
    }
   

}