* {
    font-family: "Roboto", sans-serif;
    margin: 0;
  }
  
  body {
      margin: 0;
      text-decoration: none!important;
  }  
  
  label {
      font-weight: bold!important;
  }
  ol li::marker{
    color: #92BB4D;
    font-size: 20px;
    font-weight: 800;
    line-height: 23.87px;
  }

*::-webkit-scrollbar {
    -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
    width:8px;
    height: 20px;
    margin-top: 10px;
}

*::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
    display: none;
} 

*::-webkit-scrollbar:start{
    margin-top: 15px;
}

*::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

*::-webkit-scrollbar-track {
    border-radius: 50px;  
}
input:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.608) !important
}
.MuiDrawer-paperAnchorDockedLeft {
    border-right: none !important;
}

.MuiDataGrid-row:first-of-type {
    border-top: 1px solid #f2f2f2;
}

.MuiDataGrid-row:nth-of-type(even) {
    background-color: #fafafa !important;
}
.MuiDataGrid-row:nth-of-type(odd) {
    background-color: #fff !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}

.MuiDataGrid-cell {
    outline: none !important;
}
.MuiDataGrid-cell:hover {
    outline: none !important;
}
.MuiDataGrid-row:hover {
    background-color: #f2f2f2 !important;
}
.MuiDataGrid-columnHeader {
    outline: none !important;
}
