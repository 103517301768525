.containerTitle{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;   
    margin-top: 20px;
   
    h1{ 
        text-align: center;
        color:#006E54;
        font-size: 28px;
        font-weight: 600;
    }
    p{
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        color: #2b2b2a;
        font-weight: 400;
        text-wrap: balance;
    }
}
.containerBtn{
    margin-top: 10px;
    span{ 
        cursor: pointer;
        color:#2b2b2a;
        font-weight:550;
    }
}