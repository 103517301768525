div.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    div.modal {
        background-color: #FFF;
        border: 2px solid #006E54;
        border-radius: 16px;
        padding: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-bottom: 2em;
        }
        h4 {
            color: #5567AE;
            text-align: center;
            font-size: 2.5em;
            margin-bottom: 1em;
            font-weight: 300;
        }
        button {
            border-radius: 100px;
            font-size: 2em;
            text-align: center;
            padding: 0.5em;
            width: 374px;
            border: none;
            cursor: pointer;
            background: transparent;
            color: #A7A7A7;
            font-weight: 300;
        }
    }
}