.container{
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 20px;
    margin-bottom: 24px;
}
.step{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
}
.stepImg{
    position: relative;
    img{
        width: 46px;
        height: 46px;
    }
}
.stepImg::after{
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: #d9d9d9;
    top: 40%;
    right: -61px;
}
.step:last-child{
    .stepImg::after{
        display: none;
    }
}
.stepText{
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: #d9d9d9;
}
.select{
    .stepImg{
        filter: brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(3084%) hue-rotate(119deg) brightness(86%) contrast(101%);
    }
    .stepText{
        color: #006E54;
    }
    .stepImg::after{
        filter: opacity(0.3);
    }
}
.past{
    .stepImg{
        filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(3210%) hue-rotate(102deg) brightness(109%) contrast(95%);
    }
    .stepText{
        color: #63D1A2;
    }
}