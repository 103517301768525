.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #eee;
    width: 800px;
    padding: 12px 18px;
    accent-color: #006E54 !important;
    .containerLeft{
        display: flex;
        input{
            color: #006E54 !important;
            accent-color: #006E54 !important;
        }
        h2{
            font-size: 20px;
            font-weight: 700;
            line-height: 28.64px;
            color: #006E54;
        }
        p{ 
            font-size: 18px;
            font-weight: 400;
            line-height: 23.87px;
            color: #595959;
        }
    }
    h3{
        font-size: 22px;
        font-weight: 800;
        line-height: 28.64px;
        text-align: right;
        color: #006E54;
    }
}