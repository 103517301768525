.container{
    padding: 1em 4em;
    position: sticky;
    box-sizing: border-box;
    width: 100%;
    background-color: #006E54;
    height: 10vh;
    display: flex;
    justify-content: center;
}
.logo{
    max-width: 110px;
    max-height: 100%;
}